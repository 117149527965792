
export const updateUitData = data => ({ 
    type: "UPDATE_UIT_DATA", 
    data 
})

export const emptyUitData = data => ({ 
    type: "EMPTY_UIT_DATA", 
    data 
})
