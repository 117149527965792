import './assets/css/App.css';
import "@identitybuilding/idb-react-ui-elements/dist/styles/Colors.css";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Main from "./components/Main"
import DetailsPage from "./components/DetailsPage"
import Meta from './components/Meta';
import { useEffect } from 'react';
import CookieMessage from './components/CookieMejsxssage';

const App = () => {
  useEffect(() => {

    // if(window.location.hostname !== 'localhost' && (!window.location.href.includes('mijn') || !window.location.href.includes('ma'))) {
    //   if (window.location.hostname.includes('stad-gemeente')){
    //     window.location.href = `https://mijn.stad-gemeente.be${window.location.pathname}`
    //   }
    //   else {
    //     window.location.href = `https://mijn.stad-gemeente.be${window.location.pathname}`
    //   }
    // }
    if (window.location.hostname !== 'localhost') {
      if (window.location.hostname.includes("stad-gemeente.be")) {
        if (!window.location.hostname.includes("mijn")) {
          window.location.href = `https://mijn.stad-gemeente.be${window.location.pathname}`
      }
    }
    else if (window.location.hostname.includes("ville-commune.be")) {
      if (!window.location.hostname.includes("ma")) {
        window.location.href = `https://ma.ville-commune.be${window.location.pathname}`
    }
  }
  }
    

  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <Meta />
        {/* <Login /> */}
        <CookieMessage />
        <Switch>
          <Route exact path="/" render={(props) => <Main {...props}/>} />
          <Route path="/:municipality" render={(props) => <DetailsPage {...props} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
