import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios'
import { updateUitData } from "../actions/index"

const UIT = (props) => {
  let [data, setData] = useState("")
  let [loaded, setLoaded] = useState(false)
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  const uitData = useSelector((state) => state.general.uitData);
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  let dispatch = useDispatch()

  // useEffect(() => {
  //   if (uitData.length >= props.loadAmount) {
  //     setData(uitData[props.index])
  //     setLoaded(true)
  //   }
  //   else {
  //     axios.get(`${props.uit_data['@id']}`)
  //     .then((res) => {
  //       dispatch(updateUitData(res.data))
  //       setData(res.data)
  //     })
  //     .then(() => {
  //       setLoaded(true)
  //     })
  //   }

  // }, [])


  return (
    <div className="uit_item">
      <div className="uit_item_image">
        <img src={props.uit_data.image ? props.uit_data.image : props.data.logo[`png_${lang}`]} alt={props.uit_data.name[lang]} />
      </div>
      <div className="uit_item_details">
        <h2>{props.uit_data.name[lang]}</h2>
        <span>{`${new Date(props.uit_data.availableFrom).toLocaleDateString('fr-FR', options)} ${translate("to").toLowerCase()}  ${props.uit_data.availableTo ? new Date(props.uit_data.availableTo).toLocaleDateString('fr-FR', options) === '01/01/2100' ? '' : new Date(props.uit_data.availableTo).toLocaleDateString('fr-FR', options) : ""}`}</span>
        <p>{props.uit_data.description ? props.uit_data.description[lang] : ""} </p>
        <a href={props.uit_data.sameAs ? props.uit_data.sameAs['0'] : ""} target="_blank" rel="noopener noreferer">{translate('read_more')}</a>
      </div>
    </div>
  )
}

export default UIT